import LocalisedStrings from "../Definitions/LocalisedStrings";

export default function Footer(props: { website?: string }) {
    return (
        <footer className="bg-white p-3 sm:p-4">
            <div className="max-w-(--breakpoint-xl) mx-auto flex flex-row items-center justify-between text-black text-nowrap font-bold text-xs sm:text-sm">
                <a className="w-full sm:w-1/3 text-left hover:underline" href={props.website} target="_blank" rel="noreferrer">
                    &copy; {new Date().getFullYear()} {LocalisedStrings.GetString('website')}
                </a>
                <span className="w-full sm:w-1/3 text-right lg:text-center">
                    {LocalisedStrings.GetString('phone')}
                </span>
                <span className="w-full sm:w-1/3 text-center lg:text-right hidden lg:block">
                    {LocalisedStrings.GetString('credits')}
                </span>
            </div>
        </footer>
    )
}