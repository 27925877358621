import CMSConstants from "./CMS/Schemas/CMSConstants";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Button from "./components/Button";
import News from "./components/News";
import Alert from "./components/Alert";
import useCMS from "./hooks/useCMS";

export default function App() {
  const query = new URLSearchParams(window.location.search);

  const contentUrl = new URL(import.meta.env.VITE_HOST);
  contentUrl.pathname = '/api/v2/content';
  contentUrl.searchParams.append('branch', query.get('branch') || import.meta.env.VITE_CMS_BRANCH);

  const cmsData = useCMS(contentUrl, true);
  if (!cmsData) {
    return null;
  }

  const BackgroundSettings = cmsData.SettingsBackground.get(CMSConstants.Singleton);
  const NewsSettings = cmsData.SettingsNews.get(CMSConstants.Singleton);
  const LogoSettings = cmsData.SettingsLogos.get(CMSConstants.Singleton);
  const Website = cmsData.SettingsLinks.get('website');
  const Alerts = Array.from(cmsData.Alerts.values()).filter(a => new Date(a.StartsAt) <= new Date() && new Date(a.EndsAt) >= new Date());
  const Buttons = Array.from(cmsData.Buttons.values()).sort((a, b) => a.Sort - b.Sort || a.Id.localeCompare(b.Id));

  return (
    <div className="flex flex-col h-screen">
      <div className="absolute w-full h-full bg-cover bg-center -z-20 opacity-50" style={{ backgroundImage: `url(${BackgroundSettings?.Image})` }} />
      <div className="absolute w-full h-full bg-primary/80 -z-10 backdrop-blur-xs" />
      <Header website={Website?.Value} logo={LogoSettings?.Large} icon={LogoSettings?.Small} />
      {Alerts && Alerts.map((a, i) => <Alert key={i} title={a.Title} message={a.Message} />)}
      {NewsSettings?.Enabled && <News count={NewsSettings?.Count} showDate={NewsSettings?.ShowDate} />}
      <main className="grow overflow-auto">
        <div className="flex items-center justify-center min-h-full">
          <div className="w-full max-w-(--breakpoint-xl) sm:bg-white/20 rounded-xl sm:border border-white/20 py-8">
            <div className="flex flex-wrap items-start justify-center gap-y-8 gap-x-4 sm:gap-y-6 sm:gap-x-2">
              {Buttons.map((b, i) => <Button key={i} text={b.Text} image={b.Image} href={b.Href} newTab={b.NewTab} disabled={b.Disabled} />)}
            </div>
          </div>
        </div>
      </main>
      <Footer website={Website?.Value} />
    </div>
  )
}