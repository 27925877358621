import { useEffect, useState } from "react";
import { PortalCMSData } from "../CMS/PortalCMSData";
import LocalisedStrings from "../Definitions/LocalisedStrings";

/**
 * Custom hook to fetch and manage CMS data.
 *
 * @param {URL} url - The URL to fetch the CMS content from.
 * @param {boolean} useParserV2 - Flag to determine whether to use the codegen parser.
 * @returns {PortalCMSData | null} - The parsed CMS content or null if not yet handled.
 *
 * @example
 * const cmsData = useCMS(new URL('https://cms.botfuel.dev/api/client_programming_test/development/content'), true);
 */
export default function useCMS(url: URL, useParserV2: boolean): PortalCMSData | null {
    const [cmsData, setCmsData] = useState<PortalCMSData | null>(null);

    const fetchContent = async () => {
        try {
            const res = await fetch(url, { method: 'GET' });
            const dict = await res.json() as Record<string, object>;
            const cmsData = new PortalCMSData(dict, useParserV2);
            setCmsData(cmsData);
            LocalisedStrings.Init(cmsData.LocalisedStrings);
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchContent();
    }, []);

    return cmsData;
}