import { LocalisedString } from "../CMS/Schemas/LocalisedString";

class LocalisedStrings {
    private _localisedStrings: Map<string, string>;

    public Init(localisedStrings: Map<string, LocalisedString>): void {
        if (this._localisedStrings != null) {
            this._localisedStrings.clear();
        }
        else {
            this._localisedStrings = new Map<string, string>();
        }

        for (const [Key, Value] of localisedStrings) {
            let localisedEntry = '';
            if (Value?.Text != null) {
                localisedEntry = Value.Text.replace(/\\n/g, '\n');
            }
            this._localisedStrings.set(Key.toLowerCase(), localisedEntry);
        }
        if (this._localisedStrings == null) {
            throw new Error('No localised strings found.');
        }
    }

    public GetString(id: string, ...parameters: string[]): string {
        if (!id) {
            return '';
        }

        const stringValue = this._localisedStrings.get(id.toLowerCase());
        if (this._localisedStrings != null && stringValue) {
            return stringValue.replace(/{(\d+)}/g, (match, number) => {
                return typeof parameters[number] != 'undefined' ? parameters[number] : match
            })
        }

        console.debug(`GetString ${id} not found!`);
        return `[${id}]`;
    }
}

export default new LocalisedStrings();