export default function Alert(props: { title: string, message: string }) {
    return (
        <header className="sticky top-16 z-10 p-4 bg-secondary text-black">
            <div className="max-w-(--breakpoint-xl) mx-auto flex flex-col text-center items-center justify-between">
                {props.title && (
                    <h3 className="text-sm font-bold">
                        {props.title}
                    </h3>
                )}
                {props.message && (
                    <p className="text-xs">
                        {props.message}
                    </p>
                )}
            </div>
        </header>
    )
}