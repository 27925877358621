import { useEffect, useState } from "react";

interface NewsEvent {
    id: string;
    title: string;
    date: string;
    url: string;
}

/**
 * Custom hook to fetch and manage news events.
 *
 * This hook fetches news data from the API endpoint specified in the environment
 * variable `VITE_HOST` and returns an array of `NewsEvent` objects.
 *
 * @returns {NewsEvent[]} An array of news events.
 *
 * @example
 * ```tsx
 * const news = useNews();
 * ```
 */
export default function useNews(): NewsEvent[] {
    const [news, setNews] = useState<NewsEvent[]>([]);

    const fetchNews = async () => {
        try {
            const newsApi = new URL(import.meta.env.VITE_HOST);
            newsApi.pathname = '/api/v2/news';
            const response = await fetch(newsApi, { method: 'GET' });
            const data = await response.json() as NewsEvent[];
            setNews(data);
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchNews();
    }, []);

    return news;
}