import { CMSGroup, CMSDataGroup, CMSField, CMSClass } from '@maxtull/botfuel-parsing-utils';
import CMSConstants from './CMSConstants';

@CMSGroup(CMSConstants.Groups.SettingsColors, true)
export class SettingsColors extends CMSDataGroup {
    @CMSField('value')
    public Value: string
}

/** @deprecated No longer used - use Tailwind colors instead */
@CMSClass(SettingsColors)
export class SettingsColorsData extends Map<string, SettingsColors> { }