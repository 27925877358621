import { useEffect, useState } from "react";

interface Weather {
    main: {
        temp: number;
    }
}

/**
 * Custom hook to fetch and return the weather data from open weather map.
 *
 * @returns {Weather | null} The current weather data or null if not yet fetched.
 *
 * @example
 * const weather = useWeather();
 * if (weather) {
 *   console.log(weather.main.temp);
 * }
 *
 * @remarks
 * This hook uses the OpenWeatherMap API to fetch the weather data. 
 * Make sure to provide a valid API key in the environment variable `VITE_OPEN_WEATHER_API_KEY`.
 *
 * @see https://openweathermap.org/current for more details on the API.
 */
export default function useWeather(): Weather | null {
    const [weather, setWeather] = useState<Weather | null>(null);

    const fetchWeather = async () => {
        try {
            const response = await fetch(`https://api.openweathermap.org/data/2.5/weather?q=Adelaide&units=metric&appid=${import.meta.env.VITE_OPEN_WEATHER_API_KEY}`);
            const data = await response.json() as Weather;
            setWeather(data);
        }
        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchWeather();
    }, []);

    return weather;
}